<template>
	<div>
		<b-form-select v-model="selected" :options="options" :class="validateError != '' ? 'box-border-color' : ''"
			@input="handleInput"></b-form-select>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError">
		</span>
	</div>
</template>

<script>
export default {
	props: {
		value: {
			default: null
		},
		validateError: {
			type: String,
			default: ''
		}
	},
	watch: {
		value: function (newValue) {
			this.selected = newValue;
		},
	},
	data() {
		return {
			selected: null,
			options: [
				{
					value: null,
					text: this.$t('not_printed'),
				},
				{
					value: 1,
					text: this.$t('printed'),
				},
			]
		}
	},
	created() {
		this.selected = this.value
	},
	methods: {
		handleInput(event) {
			this.$emit('input', event)
		}
	}
}
</script>
